import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-luzygas-dialog',
  templateUrl: './luzygas-dialog.component.html',
  styleUrls: ['./luzygas-dialog.component.scss']
})
export class LuzygasDialogComponent implements OnInit{

  // declarations
  eleccion: string;
  numContratosLuz: number;
  numContratosGas: number;
  entorno: string;
  replyTo: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LuzygasDialogComponent>){
    this.dialogRef.updateSize('100%','100%');
    this.eleccion = "none";
    this.numContratosLuz = this.data.numContratosLuz;
    this.numContratosGas = this.data.numContratosGas;
    this.entorno = this.data.entorno;
    this.replyTo = this.data.EmailContacto;

  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close(this.eleccion);
  }

  clickEleccion(eleccion){
    this.eleccion = eleccion;
    // this.dialogRef.close(this.eleccion);
  }

}
