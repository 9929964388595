import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { MailService } from 'src/app/services/mail/mail.service';
import { Email } from '../../../interfaces/Email';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-cambios-datos-pago-contacto-envio',
  templateUrl: './cambios-datos-pago-contacto-envio.component.html',
  styleUrls: ['./cambios-datos-pago-contacto-envio.component.scss']
})
export class CambiosDatosPagoContactoEnvioComponent implements OnInit {

  // declarations
  entorno: string;

  form: FormGroup;

  IsAdministrador: boolean;
  IdCliente: number;
  Entorno: string;
  
  isLoading = false;


  /* Campos formulario */

  // PAGO
  NombrePagador: string;
  ColectivoPagador: string;
  IdentificadorPagador: string;
  TipoCobroPagador: string;
  IBANPagador: string;
  BancoPagador: string;
  MetodosPago: any;

  // CONTACTO
  DatosContacto: any[] = [];
 
  // ENVIO
  PoblacionEnvio: any;
  CodigoPostalEnvio: any;
  DireccionEnvio: any;
  NumeroEnvio: any;
  AclaradorEnvio: any;
  DatosEnvio: any;

  varOne: number;
  varTwo: number;
  resultSumOneTwo: number;
  captcha: number;

  msgError = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private fb: FormBuilder,
    // private _facturasService: FacturasService,
    // private _funcionesService: FuncionesService,
    private _snackBar: MatSnackBar,
    private _mailService: MailService,
    public dialogRef: MatDialogRef<CambiosDatosPagoContactoEnvioComponent>
    ) {
    this.dialogRef.updateSize('100%','100%');

    // this.IsAdministrador = this.data.IsAdministrador;
    // this.IdCliente = this.data.IdCliente;
    // this.Entorno = this.data.Entorno;
  }

  ngOnInit() {

    // console.log(this.data);

    // PAGO
    this.MetodosPago = this.data.MetodosPago;

    this.NombrePagador = this.MetodosPago.NombrePagador;
    this.ColectivoPagador = this.MetodosPago.ColectivoPagador;
    this.IdentificadorPagador = this.MetodosPago.IdentificadorPagador;
    this.TipoCobroPagador = this.MetodosPago.TipoCobroPagador;
    this.IBANPagador = this.MetodosPago.IBANPagador;
    this.BancoPagador = this.MetodosPago.BancoPagador;

    // CONTACTO
    if(this.data.DatosContacto.length > 0){
      this.data.DatosContacto.forEach( (item, index) => {
        this.DatosContacto.push({Valor: item.ValorContacto, Tipo: item.TipoContacto});
      });
    }

    // this.DatosContacto = this.data.DatosContacto;

    // ENVIO
    this.DatosEnvio = this.data.DatosEnvio;
    this.PoblacionEnvio = this.DatosEnvio.PoblacionEnvio;
    this.CodigoPostalEnvio = this.DatosEnvio.CodigoPostalEnvio;
    this.DireccionEnvio = this.DatosEnvio.DireccionEnvio;
    this.NumeroEnvio = this.DatosEnvio.NumeroEnvio;
    this.AclaradorEnvio = this.DatosEnvio.AclaradorEnvio;


    this.generateNumbers();

  }


  onEnviarCambios() {
    // this.isLoading = true;
    let cuerpo = '';
    let title = '';
    let replyTo = '';

    (this.data.EmailContacto !="") ? replyTo = this.data.EmailContacto : null;


    title = '<h3>Los datos solicitados para modificar por el Cliente ' + this.data.IdentificadorClienteDNI + ' son:</h3>';
    cuerpo = (this.data.EmailContacto !="") ? cuerpo + '<p><strong>Email de conacto:</strong> ' + this.data.EmailContacto  + '</p>' : cuerpo;
    cuerpo = (this.data.MetodosPago.NombrePagador != this.NombrePagador) ? cuerpo + '<p>Nombre Pagador: ' + this.NombrePagador + '</p>' : cuerpo;
    cuerpo = (this.data.MetodosPago.ColectivoPagador != this.ColectivoPagador) ? cuerpo + '<p>Colectivo Pagador: ' + this.ColectivoPagador + '</p>' : cuerpo;
    cuerpo = (this.data.MetodosPago.IdentificadorPagador != this.IdentificadorPagador) ? cuerpo + '<p>Identificador Pagador: ' + this.IdentificadorPagador + '</p>' : cuerpo;
    cuerpo = (this.data.MetodosPago.TipoCobroPagador != this.TipoCobroPagador) ? cuerpo + '<p>Tipo Cobro Pagador: ' + this.TipoCobroPagador + '</p>' : cuerpo;
    cuerpo = (this.data.MetodosPago.IBANPagador != this.IBANPagador) ? cuerpo + '<p>IBAN Pagador: ' + this.IBANPagador + '</p>' : cuerpo;

    if(this.data.DatosContacto.length > 0){
      this.data.DatosContacto.forEach( (item, index) => {
        cuerpo = (this.DatosContacto[index].Valor != item.ValorContacto) ? cuerpo + '<p>Contacto Tipo: ' + this.DatosContacto[index].Tipo + ' | ' + this.DatosContacto[index].Valor  + '</p>' : cuerpo;
      });
    }

    cuerpo = (this.data.DatosEnvio.PoblacionEnvio != this.PoblacionEnvio) ? cuerpo + '<p>Poblacion Envio: ' + this.PoblacionEnvio + '</p>' : cuerpo;
    cuerpo = (this.data.DatosEnvio.CodigoPostalEnvio != this.CodigoPostalEnvio) ? cuerpo + '<p>Ciudad Envio: ' + this.CodigoPostalEnvio + '</p>' : cuerpo;
    cuerpo = (this.data.DatosEnvio.DireccionEnvio != this.DireccionEnvio) ? cuerpo + '<p>Direccion Envio: ' + this.DireccionEnvio + '</p>' : cuerpo;
    cuerpo = (this.data.DatosEnvio.NumeroEnvio != this.NumeroEnvio) ? cuerpo + '<p>Numero Envio: ' + this.NumeroEnvio + '</p>' : cuerpo;
    cuerpo = (this.data.DatosEnvio.AclaradorEnvio != this.AclaradorEnvio) ? cuerpo + '<p>Aclarador Envio: ' + this.AclaradorEnvio + '</p>' : cuerpo;


    // console.log(cuerpo);

    if(cuerpo !=''){

      cuerpo = title + '' + cuerpo;

      let email: Email = {Asunto: 'Solicitud de Modificación de datos de cliente', Destinatario: environment.email, Cuerpo: cuerpo, ReplyTo: replyTo};

      this._mailService
        .getEnvioEmail(email)
        .subscribe(async response => {
            this.isLoading = false;
            console.log(response);

            this.msgError = "Enviado solicitud, lo tramitaremos lo antes posible."
            this._snackBar.open(this.msgError, "Gracias", {
              duration: 2000,
            });

          });

    }else{
      this.isLoading = false;
      this.msgError = "No hay cambios reflejados"
      this._snackBar.open(this.msgError, "Intentelo de nuevo", {
        duration: 2000,
      });

    }

}

// Captcha sencillo
generateNumbers(){
  this.varOne = Math.floor(Math.random() * 10);
  this.varTwo = Math.floor(Math.random() * 10);
  this.resultSumOneTwo = this.varOne + this.varTwo;
}




}
