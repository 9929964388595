import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoginService } from '../../../services/login/login.service';
import { CchService } from '../../../services/cch/cch.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import * as moment from 'moment';
import { GraficosService } from 'src/app/services/config/graficos/graficos.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cch-dialog',
  templateUrl: './cch-dialog.component.html',
  styleUrls: ['./cch-dialog.component.scss']
})
export class CchDialogComponent {

  mensajeModal: string;
  tituloModal: string;
  fechaInicio: any;
  fechaFin: any;
  arrayCupsSeleccionados: any = [];
  options: any;
  chartOptions: any;
  arrayCategorias: any[] = [];
  datosCch: any;

  updateFlag: boolean;
  oneToOneFlag: boolean;
  isTcurvas: boolean;

  constructor(
    public dialogRef: MatDialogRef<CchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _CchService: CchService,
    private _funcionesService: FuncionesService,
    private _GraficosService: GraficosService
  ) {
    this.mensajeModal = data.texto;
    this.tituloModal = data.titulo;
    this.fechaInicio = data.fechaInicio;
    this.fechaFin = data.fechaFin;
    this.arrayCupsSeleccionados = data.cupsSeleccionados;
  }

  busquedaCchPorFechas() {
    const seriesAdd = [];
    this._CchService

    if(this.isTcurvas){
      this._CchService.getTCurvas(this.arrayCupsSeleccionados, moment(this.fechaInicio).format(), moment(this.fechaFin).format())
      .subscribe(async data => {
        this.datosCch = this._funcionesService.decodificarToken(data);
        if (this.datosCch !== undefined) {
          for (const i of this.datosCch) {
            // Para cada CUPS cargo los datos vacíos
            const arrayActiva = [];
            const arrayReactiva = [];
            this.arrayCategorias = [];
            for (const e of i.ListaCCH) {
              arrayActiva.push(Number(e.ACTIVA));
              arrayReactiva.push(Number(e.REACTIVA));
              this.arrayCategorias.push(moment(e.FECHAMED).format('DD MMM YYYY (HH:mm)').toString());
            }
            seriesAdd.push(
              { name: `Activa ${i.CodigoCUPS}`, data: arrayActiva },
              { name: `Reactiva ${i.CodigoCUPS}`, data: arrayReactiva }
            );
          }

          this.options = JSON.parse(this._GraficosService.graficosOption);
          this.chartOptions = {
            lang: this.options,
            chart: { type: 'line', zoomType: 'xy', },
            saveInstance(chartInstance): void { this.chart = chartInstance; },
            title: { text: 'CCH' },
            subtitle: { text: 'Selecciona dentro del gráfico para hacer zoom' },
            xAxis: { categories: this.arrayCategorias, crosshair: true, description: 'Descripción' },
            yAxis: { title: { text: 'Consumo (Wh)' } },
            series: seriesAdd,
            credits: {
              enabled: false
            }
          };
          this.oneToOneFlag = this.updateFlag = true;
        }
      });
    }else{
      this._CchService.getFact(this.arrayCupsSeleccionados, moment(this.fechaInicio).format(), moment(this.fechaFin).format())
      .subscribe(async data => {
        this.datosCch = this._funcionesService.decodificarToken(data);
        if (this.datosCch !== undefined) {
          for (const i of this.datosCch) {
            // Para cada CUPS cargo los datos vacíos
            const arrayActiva = [];
            const arrayReactiva = [];
            this.arrayCategorias = [];
            for (const e of i.ListaCCH) {
              arrayActiva.push(Number(e.ACTIVA));
              arrayReactiva.push(Number(e.REACTIVA));
              this.arrayCategorias.push(moment(e.FECHAMED).format('DD MMM YYYY (HH:mm)').toString());
            }
            seriesAdd.push(
              { name: `Activa ${i.CodigoCUPS}`, data: arrayActiva },
              { name: `Reactiva ${i.CodigoCUPS}`, data: arrayReactiva }
            );
          }

          this.options = JSON.parse(this._GraficosService.graficosOption);
          this.chartOptions = {
            lang: this.options,
            chart: { type: 'line', zoomType: 'xy', },
            saveInstance(chartInstance): void { this.chart = chartInstance; },
            title: { text: 'CCH' },
            subtitle: { text: 'Selecciona dentro del gráfico para hacer zoom' },
            xAxis: { categories: this.arrayCategorias, crosshair: true, description: 'Descripción' },
            yAxis: { title: { text: 'Consumo (kWh)' } },
            series: seriesAdd,
            credits: {
              enabled: false
            }
          };
          this.oneToOneFlag = this.updateFlag = true;
        }
      });
    }


    this.dialogRef.close();
  }

  rechazarBusqueda() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    // Cambiamos el tipo de curva llega a true
      if(environment.isTcurvas){
        this.isTcurvas = true;
      }
    }

}
