import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-pop-up-sinpagar-dialog',
  templateUrl: './pop-up-sinpagar.component.html',
  styleUrls: ['./pop-up-sinpagar.component.scss']
})
export class FormularioPopUpSinPagarComponent implements OnInit {

  numContratosSinPagar: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FormularioPopUpSinPagarComponent>){
    this.dialogRef.updateSize('100%','100%');
    this.numContratosSinPagar = this.data.numContratosSinPagar;
  }
  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  clickEleccion(){
    this.dialogRef.close();
  }
}
