import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../config/global/global.service';

@Injectable({
  providedIn: 'root'
})
export class ResumenService {
  urlApi: string;
  token: string;
  adminInfo: any;

  constructor(private http: HttpClient, private _globalService: GlobalService) {
    this.urlApi = this._globalService.getUrlApi();
    this.token = localStorage.getItem('token');
  }

  getAdminInfo(id, isAdmin): Observable<any> {
    const body = { Id: id, IsAdministrador: isAdmin};
    return this.http.post<any>(this.urlApi + 'Resumen/GetInfoAdmin', body, this._globalService.getHttpHeaders());
  }

}
