import { Component, OnInit, Inject, ɵConsole } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MailService } from 'src/app/services/mail/mail.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Email } from '../../../interfaces/Email';
import { environment } from 'src/environments/environment';
import { LoginComponent } from '../../login/login.component';

@Component({
  selector: 'app-multilogin-dialog',
  templateUrl: './multilogin-dialog.component.html',
  styleUrls: ['./multilogin-dialog.component.scss']
})

export class MultiloginDialogComponent implements OnInit {
  // declarations
  //option:any;
  form: FormGroup;
  texto: any;
  asunto: any;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private _mailService: MailService,
    public dialogRef: MatDialogRef<MultiloginDialogComponent>) {
    this.dialogRef.updateSize('100%', '100%');
  }

  loginmultiapi() {

    var companySelected = document.getElementsByTagName("mat-select")[0].textContent;
    this.dialogRef.close(companySelected);
  }

  ngOnInit() {
    this.form = this.fb.group({
      texto: new FormControl(),
      option: new FormControl(),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clickEleccion() {
    // this.dialogRef.close(this.eleccion);
  }
}
