import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Global } from '../../../interfaces/config/global';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GlobalService {

  constructor() {}

  url: any;
  urlOV = environment.urlApi;
  urlCCH = environment.urlCch;
  cryptoKey= environment.cryptoKey;
  cryptoIV= environment.cryptoIV;
  user = environment.user;
  password = environment.password;

  getUrlApi(): string { return (localStorage.getItem('urlApi')) ? localStorage.getItem('urlApi') : this.urlOV; }

  getUsuarioApi(): Global { return { Usuario: this.user, Password: this.password }; }

  getUrlApiCch() { return (localStorage.getItem('urlCCH')) ? localStorage.getItem('urlCCH') : this.urlCCH; }

  getHttpHeaders(): object {
    const options = { headers: new HttpHeaders({'Content-Type': 'application/json', Authorization: localStorage.getItem('token')}) };
    return options;
  }

  getCryptoKey(): string {
    return (localStorage.getItem('cryptoKey')) ? localStorage.getItem('cryptoKey') : this.cryptoKey;
  }

  getCryptoIV(): string {
    return (localStorage.getItem('cryptoIV')) ? localStorage.getItem('cryptoIV') : this.cryptoIV;
  }
}
