import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent {
  mensajeModal: string;
  tituloModal: string;
  estado: boolean;
  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.mensajeModal = data.texto;
    this.tituloModal = data.titulo;
    this.estado = data.estado;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
