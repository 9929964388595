import { Component, OnInit, ViewChild } from '@angular/core';
import { Contrato } from '../../../interfaces/contrato';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { LoginService } from '../../../services/login/login.service';
import { ActivatedRoute } from '@angular/router';
import { LoginDialogComponent } from '../../dialogs/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material';
import { ValidacionesPerfilService } from '../../../services/config/validaciones/perfil/validaciones-perfil.service';
import {
  MatSort,
  MatTableDataSource,
  MatPaginator

} from '@angular/material';

import { Router } from '@angular/router';

import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-perfil-cups',
  templateUrl: './perfil-cups.component.html',
  styleUrls: ['./perfil-cups.component.scss']
})
export class PerfilCupsComponent implements OnInit {

  displayedColumns: string[] = [
    'NumFinca',
    'CodigoContrato',
    'ContratoSituacion',
    'Cups',
    'FechaAlta',
    'FechaVto',
    'FechaBaja',
  ];

  dataSource = new MatTableDataSource<Contrato>();

  DatosCups: any;

  CodigoCups: string;
  Distribuidora: string;
  CodigoCnae: string;
  RazonSocial: string;
  Nombre: string;
  Apellidos: string;
  CodigoPostal: string;
  Ciudad: string;
  Direccion: string;
  Numero: number;
  Aclarador: string;
  Observaciones: string;

  textoModal: any;

  PerfilCupsCodigoCupsDisabled: boolean;
  PerfilCupsDistribuidoraDisabled: boolean;
  PerfilCupsCodigoCnaeDisabled: boolean;
  PerfilCupsRazonSocialDisabled: boolean;
  PerfilCupsNombreDisabled: boolean;
  PerfilCupsApellidosDisabled: boolean;
  PerfilCupsCodigoPostalDisabled: boolean;
  PerfilCupsCiudadDisabled: boolean;
  PerfilCupsDireccionDisabled: boolean;
  PerfilCupsNumeroDisabled: boolean;
  PerfilCupsAclaradorDisabled: boolean;
  PerfilCupsObservacionesDisabled: boolean;

  contratos: Contrato[];
  idCliente: any;
  mostrarSpinner = true;
  visibleFacturas: boolean;
  monopunto: boolean;
  isLoading = true;
  tokenvalidado: any;
  tipocliente: any;
  MostrarDescargaMasivaFacturasPorCups: any;
  idCups: any;
  isActivo: boolean;

  CambiarTitularElekluz: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  agrupacontratoscups = environment.agrupacontratoscups;

  constructor(
    private router: Router,
    private _contratosService: ContratosService,
    private _funcionesService: FuncionesService,
    private _loginService: LoginService,
    private _activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private _validacionesPerfilService: ValidacionesPerfilService,
  ) {}

  ngOnInit() {

    this.CambiarTitularElekluz = environment.CambiarTitularElekluz;

    console.log(this.CambiarTitularElekluz);

    this.displayedColumns = this.displayedColumns.filter(function (col) {
      return col.localeCompare('NumFinca'); // Quitar columna NumFinca
    });
    this._activatedRoute.params.subscribe(params => {
      /** CONTROLO SI EL ARRAY DE LOS DATOS DEL CUPS ESTA VACIO O NO */
      if (this.DatosCups === undefined) {
        /** EN CASO DE QUE ESTE VACIO, LLAMO A LA LLAMADA DE OBTENER DATOS DEL CUPS Y LOS MUESTRO EN LA VISTA.  */
        this._contratosService.getDatosCups(params.idcups).subscribe(data => {
          this.DatosCups = this._funcionesService.decodificarToken(data);

          /** CONTROLO QUE SE REALICEN SOLO BUSQUEDAS RELACIONADAS CON LOS IDCUPS EXISTENTES EN EL ARRAY DEL LISTADO DE CONTRATOS */
          // let resultado = '';
          // this.idCups = params.idcups;
          // console.log(this._contratosService.listadoContratos);
          // if (this._contratosService.listadoContratos === undefined) {
          //   console.log('Entra 1');
          //   this._contratosService.getListadoContratos(localStorage.getItem('idCliente')).subscribe(
          //     datos => {
          //       this._contratosService.listadoContratos = this._funcionesService.decodificarToken(datos);
          //       resultado = this._contratosService.listadoContratos.find(
          //         idcups => idcups.IdCups === params.idcups
          //       );
          //     }
          //   );
          //   console.log(resultado);
          // } else {
          //   // console.log('Entra 2');
          //   // resultado = this._contratosService.listadoContratos.find(
          //   //   idcups => idcups.IdCups === params.idcups
          //   // );
          //   // console.log(resultado);
          // }

          /** DOY VALORES A LOS CAMPOS DE LA VISTA DEPENDIENDO DEL RESULTADO DE LA COINCIDENCIA EN EL ARRAY DEL LISTADO. */
          // if (resultado !== undefined) {
            this.CodigoCups = this.DatosCups.CodigoCUPS;
            this.Distribuidora = this.DatosCups.Distribuidora;
            this.CodigoCnae = this.DatosCups.CNAE;
            this.RazonSocial = this.DatosCups.RazonSocial;
            this.Nombre = this.DatosCups.Nombre;
            this.isActivo = this.DatosCups.IsActivo;

            /** IF PARA CONTROLA LOS APELIIDOS */
            if (
              this.DatosCups.Apellido1 === '' &&
              this.DatosCups.Apellido2 === ''
            ) {
              this.Apellidos = '';
            } else {
              this.Apellidos =
                this.DatosCups.Apellido1 + ' ' + this.DatosCups.Apellido2;
            }

            this.CodigoPostal = this.DatosCups.CodPostal;
            this.Ciudad = this.DatosCups.Ciudad;
            this.Direccion = this.DatosCups.Calle;
            this.Numero = this.DatosCups.Numero;
          //   this.Aclarador = this.DatosCups.Aclarador;
          // } else {
          //   this.textoModal = 'El CUPS buscado no corresponde con tus datos de administrador';
          //   this.dialog.open(LoginDialogComponent, {
          //     width: '400px',
          //     data: {
          //       texto: this.textoModal,
          //       titulo: 'CUPS erróneo.'
          //     }
          //   });
          // }
        });


        if(this.agrupacontratoscups){
          this.tipocliente = localStorage.getItem('TipoCliente');
          this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
            data => {
              this.tokenvalidado = this._funcionesService.decodificarToken(data);
              if(this.tokenvalidado.Valido == true){
          this._contratosService
            .getListadoContratos(localStorage.getItem('idCliente'), params.idcups)
            .subscribe(async data => {
              this.isLoading = false;
              this.contratos = this._funcionesService.decodificarToken(data);
              this.monopunto = (this.contratos.length === 1) ? true : false;
              this.dataSource = new MatTableDataSource<Contrato>(this.contratos);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.mostrarSpinner = false;
            },
              err => {
                this.isLoading = false;
                this.textoModal = this._funcionesService.decodificarToken(
                  err.error
                )['0'].Error;
                this.mostrarSpinner = false;
                this.dialog.open(LoginDialogComponent, {
                  width: '400px',
                  data: {
                    texto: this.textoModal,
                    titulo: 'Error en contratos'
                  }
                });
              }
            )}else{
              this.router.navigate(['/salir']);
            }});
          }
        }
      });


    this.PerfilCupsCodigoCupsDisabled = this._validacionesPerfilService.PerfilCupsCodigoCupsDisabled;
    this.PerfilCupsDistribuidoraDisabled = this._validacionesPerfilService.PerfilCupsDistribuidoraDisabled;
    this.PerfilCupsCodigoCnaeDisabled = this._validacionesPerfilService.PerfilCupsCodigoCnaeDisabled;
    this.PerfilCupsRazonSocialDisabled = this._validacionesPerfilService.PerfilCupsRazonSocialDisabled;
    this.PerfilCupsNombreDisabled = this._validacionesPerfilService.PerfilCupsNombreDisabled;
    this.PerfilCupsApellidosDisabled = this._validacionesPerfilService.PerfilCupsApellidosDisabled;
    this.PerfilCupsCodigoPostalDisabled = this._validacionesPerfilService.PerfilCupsCodigoPostalDisabled;
    this.PerfilCupsCiudadDisabled = this._validacionesPerfilService.PerfilCupsCiudadDisabled;
    this.PerfilCupsDireccionDisabled = this._validacionesPerfilService.PerfilCupsDireccionDisabled;
    this.PerfilCupsNumeroDisabled = this._validacionesPerfilService.PerfilCupsNumeroDisabled;
    this.PerfilCupsAclaradorDisabled = this._validacionesPerfilService.PerfilCupsAclaradorDisabled;
    this.PerfilCupsObservacionesDisabled = this._validacionesPerfilService.PerfilCupsObservacionesDisabled;
  }
  
}
