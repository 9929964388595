import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { PasswordService } from '../../services/password/password.service';
import { FuncionesService } from '../../services/funciones/funciones.service';
import { MatDialog } from '@angular/material';
import { LoginDialogComponent } from '../../components/dialogs/login-dialog/login-dialog.component';
import { ConditionalExpr } from '@angular/compiler';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/config/global/global.service';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-recordar-contrasena',
  templateUrl: './recordar-contrasena.component.html',
  styleUrls: ['./recordar-contrasena.component.scss']
})
export class RecordarContrasenaComponent implements OnInit {

  marca: any;
  multiapi: any;
  peticionAPIs: any;
  urlApi: string;
  contador = 1;
  intentosExitosos = 0;

  constructor(
    private _funcionesService: FuncionesService,
    private _passwordService: PasswordService,
    public dialog: MatDialog,
    private _loginService: LoginService,
    private route: Router,

    private _globalService: GlobalService,

  ) {
    this.urlApi = this._globalService.getUrlApi();
    this.marca = environment.marca;

    this.multiapi = environment.multiapi;
    this.peticionAPIs = environment.peticionAPIs;
  }

  recuperarPassword(f: NgForm) {
    if (f.valid) {
      if( f.value.identificador.indexOf("'")>= 0){
        this.dialog.open(LoginDialogComponent, {
          width: '400px',
          data: {
            texto: 'DNI contiene caracteres no permitidos.',
            titulo: 'Error al restablecer la contraseña'
          }
        });
      } else {
        if (this.marca == "") {

          if (this.multiapi) {
            this.contador=0;
            this.intentosExitosos=0;

            for (const peticion of this.peticionAPIs) {
              this._passwordService.getRecuperarPassword(f.value.identificador.toUpperCase(), peticion.urlApi).subscribe(
                data => {
                  this.contador++;
                  this.intentosExitosos++;
                  console.log("entra");
                  console.log(this.intentosExitosos);
                  console.log(this.contador);
                  this.MostrarDialogMultiapi();
                },
                err => {
                  this.contador++;
                  console.log("no entra")
                  this.MostrarDialogMultiapi();
                });

            }

          } else {
            this._passwordService.getRecuperarPassword(f.value.identificador.toUpperCase(), this.urlApi).subscribe(
              data => {
                this.dialog.open(LoginDialogComponent, {
                  width: '400px',
                  data: {
                    titulo: 'Contraseña restablecida correctamente',
                    texto: 'Su contraseña se ha restablecido correctamente. En breve recibirá en su correo electrónico su nueva contraseña.',
                    estado: true,

                  }
                });
              },
              err => {
                this.dialog.open(LoginDialogComponent, {
                  width: '400px',
                  data: {
                    titulo: 'Error al restablecer la contraseña',
                    texto: 'Ha ocurrido un error al restablecer su contraseña. Compruebe si el DNI / CIF introducido es correcto.'
                  }
                });
              });
          }

        } else {

          this._passwordService.getRecuperarPasswordIdMarca(f.value.identificador.toUpperCase(), this.marca).subscribe(
            data => {
              this.dialog.open(LoginDialogComponent, {
                width: '400px',
                data: {
                  titulo: 'Contraseña restablecida correctamente',
                  texto: 'Su contraseña se ha restablecido correctamente. En breve recibirá en su correo electrónico su nueva contraseña.',
                  estado: true,

                }
              });
            },
            err => {
              this.dialog.open(LoginDialogComponent, {
                width: '400px',
                data: {
                  titulo: 'Error al restablecer la contraseña',
                  texto: 'Ha ocurrido un error al restablecer su contraseña. Compruebe si el DNI / CIF introducido es correcto.'
                }
              });
            });

        }
      }
    }
  }

  ngOnInit() {
  }

  MostrarDialogMultiapi() {
    if (this.contador == this.peticionAPIs.length && this.intentosExitosos > 0) {
      this.dialog.open(LoginDialogComponent, {
        width: '400px',
        data: {
          titulo: 'Contraseña restablecida correctamente',
          texto: 'Su contraseña se ha restablecido correctamente. En breve recibirá en su correo electrónico su nueva contraseña.',
          estado: true,

        }
      });
    } else if(this.contador == this.peticionAPIs.length) {
      console.log(this.peticionAPIs.length);
      console.log(this.intentosExitosos + " sad, very sad")
      this.dialog.open(LoginDialogComponent, {
        width: '400px',
        data: {
          titulo: 'Error al restablecer la contraseña',
          texto: 'Ha ocurrido un error al restablecer su contraseña. Compruebe si el DNI / CIF introducido es correcto.',
        }
      });
    }
  }
}
