export const environment = {
  production: true,
  messageLogin: false,
  isTcurvas: true,
  user: 'U2FuZHJh',
  password: 'U2FuZHJh',
  urlApi: 'https://oficinasige.gruporemica.net:84/remica/',
  urlCch: 'https://oficinasige.gruporemica.net:84/remicaTM/',
  cryptoKey: 'rZiyew0GIlwmy1j1JtB1zlttrSGCLEdb',
  cryptoIV: 'DEwbbvQZWJA859O0',
  companyName: 'REMICA COMERCIALIZADORA, S.A.U.',
  companyAddress: 'Calle Gamonal 25 28031-Madrid',
  companyEmail: 'gestionclientes@remica.es',
  companyPhone: '900 103 644',
  urlAvisoLegal: 'https://www.remica.es/aviso-legal',
  urlCookies: '',
  urlUso: '',
  UserAccess: 'DNI/CIF/NIF/EMAIL',
  TextoInicioSesion: 'INICIO DE SESIÓN',
  HabilitarTPV: false,
  iconoresumennuevalectura: 'fab fa-searchengin fa-4x',
  iconoresumendatospago: 'far fa-credit-card-blank fa-4x',
  iconoresumenconsulta: 'far fa-exclamation-square fa-4x',
  alinearmenusuperior: 'izquierda', // derecha izquierda o centro
  MostrarConsumosPorHora: true,
  idiomas: true,
  email: 'vrodriguez@remica.es',// email al que llegaran todos los formularios de la oficina virtual
  comparativa: true, //Gráfico de resumen comparativa importes facturas
  historico: false, //Gráfico de resumen histórico importes facturas
  DisabledBotonModificarDatos:false,//Botón modificardatos
  DisabledBotonRealizarConsulta:false,//Botón realizar consulta
  DisabledBotonNuevaLectura:false, //Botón realizar nueva lectura
  MostrarTarifaGrupo:true,
  MostrarFacturaExcel:false,
  MostrarPerfilFacturacion:true,
  RegistrarAceptacionDeUso: false,
  VersionCondicionesUso: "V1",
  MostrarPreciosContrato: false,
  MostrarBotonFacturasImpagadas: false,
  MostrarPerfilContratoSoloUnaPotenciaDHA:false,
  TiempoEsperaGraficos: '500',
  DialogFacturasPendientePago: true,
  CambiaTituloCChGraph: true,
  agrupacontratoscups: false,
  MostrarDescargaMasivaFacturasPorCups: false,
  internalApiUrl: '',
  mesesgraficocch: 1,
  maxCUPSgraficoCCH: 150,
  MostrarBotonModificiacionDatosPerfil: true,
  MostrarDeuda: true,
  PrefijoNumeracionFacturas: '',
PrefijoNumeracionContratos: '',
  GraficoConecta2: false,
  MostrarReactivaComparativaConsumos : true,
  OcultaTipoContacto1: '',
  UltimasFacturasResumen: null,
  DobleGraficoConsumosPorHora: false,
  mostrarFechaVencimientoFactura: true,
  mostrarLimitePagoFactura: false,
  _URL_ : "http://80.35.34.4:8383/ov/remica",
  messageRecuperarPass: false,
  MostrarAdministradorListadoContratoFechaAlta: true,
  MostrarAdministradorListadoContratoFechaVto: true,
  MostrarAdministradorListadoContratoFechaBaja: true,
  MostrarAdministradorListadoContratoEntorno: true,
  MostrarListadoContratoDireccionSuministro:false,
  MostrarConsumosPorHoraClientes: true,
  MostrarPerfilContratoColectivo: true,
  UnirPerfilDatosEnvioYContacto: false,
  MostrarDatosContratoEnPerfil: false,
  marca: '',
  ApiCallContratosListaElekluz: false,
  defaultLang:'es',
  OcultarFacturasConecta2DeAntesDel31x11x19: false,
  cchWitchCups: false,
  MostrarDescargaMasivaFacturasBotonExcel:false,
  MostrarDescargaMasivaFacturasFiltroCif:false,
  MostrarDescargaMasivaFacturasFiltroImporte:false,
  MostrarDescargaMasivaFacturasFiltroTarifa:false,
  MostrarListadoContratoDenominacionCliente:false,
  MostrarResumenGraficosDeMultipuntoAAdministradorFincas:false,
  MostrarListadoContratoBotonGraficos: false,
  CambiarTitularElekluz:false,
  BotonElekluz:false,
  OcultarContactosEleia:false,
  SimplificacionSerieFactura:false,
  MensajeCCHConExplicacion:false,
  peticionAPIs:[],
  multiapi:false,
  EleiaNombreAdministradorFincas:false,
  urlPagininaInicial:'',
};
