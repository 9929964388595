import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { ValidacionesPerfilService } from 'src/app/services/config/validaciones/perfil/validaciones-perfil.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-perfil-contrato',
  templateUrl: './perfil-contrato.component.html',
  styleUrls: ['./perfil-contrato.component.scss']
})
export class PerfilContratoComponent implements OnInit {
  @Input() codigo: string;
  DatosContrato: any;
  Potencias: any;
  Tarifa: any;
  NumeroContratoDistribuidora: any;
  TarifaPeaje:any;
  TarifaGrupo: any;
  PerfilFacturacion: any;
  CodigoContrato: string;
  CodigoPostal: string;
  CodigoCups: string;
  IdCups: string;
  ContratoSituacion: string;
  FechaAlta: string;
  FechaBaja: string;
  FechaVto: string;
  FechaContrato: string;
  NombreComercializadora: string;
  NombreDistribuidora: string;
  Identidad: string;
  TextoCiudad: string;
  TextoProvincia: string;
  Direccion: string;
  Deuda: string;
  PreciosPotencias: any;

  PerfilContratoNombreComercializadora: boolean;
  PerfilContratoNombreDistribuidora: boolean;
  PerfilContratoCodigoContrato: boolean;
  PerfilContratoCodigoCups: boolean;
  PerfilContratoContratoSituacion: boolean;
  PerfilContratoIdentidad: boolean;
  PerfilContratoFechaAlta: boolean;
  PerfilContratoFechaBaja: boolean;
  PerfilContratoFechaContrato: boolean;
  PerfilContratoFechaVto: boolean;
  PerfilContratoTextoCiudad: boolean;
  PerfilContratoDireccion: boolean;
  PerfilContratoTarifa: boolean;
  PerfilContratoTarifaGrupo: boolean;
  PerfilContratoPerfilFacturacion: boolean;

  MostrarPreciosContrato: boolean;
  MostrarPerfilContratoSoloUnaPotenciaDHA: boolean;
  PrefijoNumeracionContratos = environment.PrefijoNumeracionContratos;
  MostrarTarifaGrupo: boolean;

  CambiarTitularElekluz: boolean;
  OcultarContactosEleia = environment.OcultarContactosEleia;


  constructor(
    private _activatedRoute: ActivatedRoute,
    private _contratosService: ContratosService,
    private _funcionesService: FuncionesService,
    private _validacionesPerfilService: ValidacionesPerfilService
  ) {
  }

  ngOnInit() {

    this.CambiarTitularElekluz = environment.CambiarTitularElekluz;

    this._activatedRoute.params.subscribe(params => {
      // COMPRUEBO QUE EL ARRAY DE LOS DATOS DEL CONTRATO ESTA VACIO
      if (this.DatosContrato === undefined) {
        // USAR EL CODIGO QUE PASO EN LA TEMPLATE PARA LA PAGINA DE CONTRATOS DE MONOPUNTO
        if (this.codigo) {
          params = {};
          params.idcontrato = this.codigo;
        }

        this._contratosService.getDatosContrato(params.idcontrato)
          .toPromise()
          .then(async data => {
            this.DatosContrato = this._funcionesService.decodificarToken(data);
            // console.log(this.DatosContrato);
            this.loadData();
          });
      }
    });

    this.PerfilContratoNombreComercializadora = this._validacionesPerfilService.PerfilContratoNombreComercializadora;
    this.PerfilContratoNombreDistribuidora = this._validacionesPerfilService.PerfilContratoNombreDistribuidora;
    this.PerfilContratoCodigoContrato = this._validacionesPerfilService.PerfilContratoCodigoContrato;
    this.PerfilContratoCodigoCups = this._validacionesPerfilService.PerfilContratoCodigoCups;
    this.PerfilContratoContratoSituacion = this._validacionesPerfilService.PerfilContratoContratoSituacion;
    this.PerfilContratoIdentidad = this._validacionesPerfilService.PerfilContratoIdentidad;
    this.PerfilContratoFechaAlta = this._validacionesPerfilService.PerfilContratoFechaAlta;
    this.PerfilContratoFechaBaja = this._validacionesPerfilService.PerfilContratoFechaBaja;
    this.PerfilContratoFechaContrato = this._validacionesPerfilService.PerfilContratoFechaContrato;
    this.PerfilContratoFechaVto = this._validacionesPerfilService.PerfilContratoFechaVto;
    this.PerfilContratoTextoCiudad = this._validacionesPerfilService.PerfilContratoTextoCiudad;
    this.PerfilContratoDireccion = this._validacionesPerfilService.PerfilContratoDireccion;
    this.PerfilContratoTarifa = this._validacionesPerfilService.PerfilContratoTarifa;
    this.PerfilContratoTarifaGrupo = this._validacionesPerfilService.PerfilContratoTarifaGrupo;
    this.PerfilContratoPerfilFacturacion = this._validacionesPerfilService.PerfilContratoPerfilFacturacion;
    this.MostrarPreciosContrato = environment.MostrarPreciosContrato;
    this.MostrarPerfilContratoSoloUnaPotenciaDHA = environment.MostrarPerfilContratoSoloUnaPotenciaDHA;
    this.MostrarTarifaGrupo = environment.MostrarTarifaGrupo;
  }

  loadData() {
    this.Potencias = this.DatosContrato.Potencias;
    this.Tarifa = this.DatosContrato.Facturacion.Tarifa;
    this.TarifaPeaje = this.DatosContrato.Facturacion.TextoTarifaPeaje;
    this.TarifaGrupo = this.DatosContrato.Facturacion.TarifaGrupo;
    this.PerfilFacturacion = this.DatosContrato.Facturacion.PerfilFacturacion;
    this.CodigoContrato = this.DatosContrato.General.CodigoContrato;
    this.CodigoPostal = this.DatosContrato.General.CodPostal;
    this.CodigoCups = this.DatosContrato.General.CUPS;
    this.IdCups = this.DatosContrato.General.IdCUPS;
    this.ContratoSituacion = this.DatosContrato.General.ContratoSituacion;
    this.FechaAlta = this.DatosContrato.General.FechaAlta;
    this.FechaBaja = this.DatosContrato.General.FechaBaja;
    this.FechaVto = this.DatosContrato.General.FechaVencimiento;
    this.FechaContrato = this.DatosContrato.General.FechaContrato;
    this.NombreComercializadora = this.DatosContrato.General.NombreComercializadora;
    this.PreciosPotencias = (this.DatosContrato.PreciosPotencias) ? this.DatosContrato.PreciosPotencias : null;
    // Para solo mostrar P1 en las tarifas DHA y DHS
    /*
    if(this.MostrarPerfilContratoSoloUnaPotenciaDHA &&
      (this.TarifaPeaje == "2.0DHA" || this.TarifaPeaje == "2.1DHA" || this.TarifaPeaje == "2.0DHS" || this.TarifaPeaje == "2.1DHS")){
        //this.PreciosPotencias.PreciosPotencias[1] = null;
        console.log(this.PreciosPotencias);
      }
      */
    this.NombreDistribuidora = this.DatosContrato.General.NombreDistribuidora;
    this.Identidad = this.DatosContrato.General.Identidad;
    this.TextoCiudad = this.DatosContrato.General.TextoCiudad;
    this.Deuda = this.DatosContrato.General.Deuda;
    this.TextoProvincia = this.DatosContrato.General.TextoProvincia;
    this.Direccion = this.DatosContrato.General.Direccion;
    this.NumeroContratoDistribuidora = this.DatosContrato.General.NumeroContratoDistribuidora;
  }
}
