
import { Component, OnInit, Inject, ɵConsole } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MailService } from 'src/app/services/mail/mail.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Email } from '../../../interfaces/Email';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-modificaciones-dialog.component',
    templateUrl: './modificaciones-dialog.component.html',
    styleUrls: ['./modificaciones-dialog.component.scss']
  })

  export class ModificacionesComponent implements OnInit{
    // declarations
    //option:any;
    form: FormGroup;
    texto: any;
    asunto: any;

    constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private _mailService: MailService,
    public dialogRef: MatDialogRef<ModificacionesComponent>){
    this.dialogRef.updateSize('100%','100%');
}

  ngOnInit() {
    this.form = this.fb.group({
      texto: new FormControl(),
      option: new FormControl(),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  consultar(){
      let cuerpo = '';
      let replyTo = '';

      (this.data.EmailContacto !="") ? replyTo = this.data.EmailContacto : null;
  
      cuerpo = '<h3>El Administrador ' + localStorage.getItem('usuarioCambioPass') + '  envía la siguiente solicitud de cambio de datos:</h3>';
  
      this.asunto = "Solicitud cambio datos de cliente de la oficina virtual";
      (replyTo!="") ? cuerpo = '<p>El cliente con email: ' + replyTo + '  </p>' : null;
      if(this.form.value.texto){cuerpo = cuerpo + '<p>' + this.form.value.texto + '</p>'; }
    let email: Email = {Asunto: this.asunto, Destinatario: environment.email, Cuerpo: cuerpo, ReplyTo: replyTo};
    this._mailService
      .getEnvioEmail(email)
      .subscribe(async data => {
          // this.isLoading = false;
          console.log(data);
        });
        this.dialogRef.close();
      }

  clickEleccion(){
     // this.dialogRef.close(this.eleccion);
  }
}
