import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { LoginService } from '../../../services/login/login.service';
import { PasswordService } from '../../../services/password/password.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { CondicionesService } from '../../../services/condiciones/condiciones.service';
import { MatDialog } from '@angular/material';
import { LoginDialogComponent } from '../../../components/dialogs/login-dialog/login-dialog.component';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  textoModal: any;
  respuesta: any;
  token: any;
  urlUso?: string;
  versionCondicionesUso?: string;
  RegistrarAceptacionDeUso?: boolean;
  MostrarAceptacionDeUso = true;

  primeraVez: any;
  tokenvalidado: any;

  constructor(
    private _loginService: LoginService,
    private _funcionesService: FuncionesService,
    private _passwordService: PasswordService,
    private _condiciones: CondicionesService,
    private location: Location,
    private router: Router,
    public dialog: MatDialog
  ) {}

  cambioContrasena(f: NgForm) {
    if (f.valid) {
      if( f.value.contrasenaNueva.indexOf("'")>= 0 || f.value.repetirContrasena.indexOf("'")>= 0 ){
        this.dialog.open(LoginDialogComponent, {
          width: '400px',
          data: {
            texto: 'Nueva contraseña contiene caracteres no permitidos.',
            titulo: 'Error en Cambio Contraseña'
          }
        });
      } else {
        if (localStorage.getItem("urlApi")){
          var urlApi = localStorage.getItem("urlApi");
        }else{
          var urlApi = environment.urlApi;
        }
        if (f.value.contrasenaNueva === f.value.repetirContrasena) {
          try{
            //Hago un btoa para comprobar que el navegador puede manejar la contraseña, si falla salta el catch
            var revientoalpasarabase64 = btoa(f.value.contrasenaNueva);
            this._loginService.getvalidaToken(localStorage.getItem('idCliente')).subscribe(
              data => {
                this.tokenvalidado = this._funcionesService.decodificarToken(data);
                if(this.tokenvalidado.Valido == true){
            this._loginService.getToken(urlApi, localStorage.getItem('usuarioCambioPass'),localStorage.getItem('passCambioPass')).subscribe( //cambiar los valores que se pasan al token
              data => {
                this.token = data.Token;
                this._passwordService.getCambioPassword(localStorage.getItem('idCliente'), f.value.contrasenaNueva, this.token).subscribe(
                  datos => {
                    this.respuesta = this._funcionesService.decodificarToken(datos);
                    // this.textoModal = this.respuesta.ResultadoUpdate;
                    this.textoModal = 'La contraseña se ha cambiado satisfactoriamente. Inicie sesión con su nueva contraseña.';
                    this.dialog.open(LoginDialogComponent, {
                      width: '400px',
                      data: {
                        texto: this.textoModal,
                        titulo: 'Cambio de Contraseña',
                        estado: true
                      }
                    });

                    // Si existe el check es que no tiene aceptada la politica asi que en el envio del formularario se añade el registro a la base de datos
                    // Variable this.RegistrarAceptacionDeUso se utiliza para el control de esta funcionalidad en los enviroments | Eleia
                    if (this.MostrarAceptacionDeUso && this.RegistrarAceptacionDeUso) { this.addCondicionesUsoCliente(); }

                    this.router.navigate(['/login']);
                  },
                  err => {
                    this.textoModal = this._funcionesService.decodificarToken(err.error)['0'].Error;
                    this.dialog.open(LoginDialogComponent, {
                      width: '400px',
                      data: {
                        texto: this.textoModal,
                        titulo: 'Error en Cambio Contraseña',
                        estado: false
                      }
                    });
                  });
              })} else {
                this.router.navigate(['/salir']);
              }});
          } catch (Error) {
            this.dialog.open(LoginDialogComponent, {
              width: '400px',
              data: {
                texto: 'La contraseña contiene carácteres especiales no validos.',
                titulo: 'Error en Cambio Contraseña',
                estado: false
              }
            });
          }
        } else {
          this.dialog.open(LoginDialogComponent, {
            width: '400px',
            data: {
              texto: 'Las contraseñas no coinciden.',
              titulo: 'Error en Cambio Contraseña',
              estado: false
            }
          });
        }
      }
    }
  }

  back() {
    this.location.back();
  }

  checkPrimeraVez() {
    if (localStorage.getItem('CambioPassword') === 'True') {
      this.primeraVez = true;
    } else {
      this.primeraVez = false;
    }
  }

  // CHECK EN HEIDI x.16 DE POLITICA DE USO | Eleia
  checkCondicionesUsoCliente() {

    let aceptacionData = {
      // "Nombre": $scope.datosTitular.NombreTitular,
      // "Version": ($scope.versionCondicionesUso) ? $scope.versionCondicionesUso : '',
      // "Usuario": ($scope.login.usuario) ? $scope.login.usuario : '',
      "Version": (this.versionCondicionesUso) ? this.versionCondicionesUso : '',
      "Usuario": (localStorage.getItem('usuarioCambioPass')) ? localStorage.getItem('usuarioCambioPass') : 'FAIL',
      "idCliente": (localStorage.getItem('idCliente')) ? localStorage.getItem('idCliente') : '',
      "Action": "checkUser"
      };

    this._condiciones.checkCondicionesUsoCliente(JSON.stringify(aceptacionData)).subscribe(
      datos => {
        // si existe el registro Cliente en base de datos que viene en datos a true/false
        this.MostrarAceptacionDeUso = !datos; // invertimos el valor para mostrar u ocultar
        console.log(this.MostrarAceptacionDeUso);
      }
    );
  }

  // Añadimos el registro una vez aceptada la politica de uso
  addCondicionesUsoCliente() {

    let aceptacionData = {
      // "Nombre": $scope.datosTitular.NombreTitular,
      // "Version": ($scope.versionCondicionesUso) ? $scope.versionCondicionesUso : '',
      // "Usuario": ($scope.login.usuario) ? $scope.login.usuario : '',
      "Version": (this.versionCondicionesUso) ? this.versionCondicionesUso : '',
      "idCliente": (localStorage.getItem('idCliente')) ? localStorage.getItem('idCliente') : '',
      "Usuario": (localStorage.getItem('usuarioCambioPass')) ? localStorage.getItem('usuarioCambioPass') : '',
      "Action": "insert"
      };

    this._condiciones.checkCondicionesUsoCliente(JSON.stringify(aceptacionData)).subscribe(
      datos => {
        console.log(datos);
      }
    );
  }

  ngOnInit() {

    this.checkPrimeraVez();
    this.urlUso = environment.urlUso;
    this.versionCondicionesUso = environment.VersionCondicionesUso;
    this.RegistrarAceptacionDeUso = environment.RegistrarAceptacionDeUso;
    if (this.RegistrarAceptacionDeUso) {this.checkCondicionesUsoCliente();} // Comprobamos que tenga a true el registro de aceptacion de uso

  }

}
